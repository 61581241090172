import { flowRight, isEqual } from 'lodash';
import React, { Component } from 'react';
import classNames from 'classnames';
import {
  SECTION_POST_LIST,
  IS_COVER_IMAGE_ENABLED_PATH,
  IS_COVER_IMAGE_ENABLED_MOBILE_PATH,
  POST_LIST_WIDGET_DEFAULTS,
  POST_LIST_SETTINGS_PARAMS,
  POST_LIST_LAYOUT_PARAMS,
  POST_LIST_LAYOUT_PARAMS_MOBILE,
  isLayoutSlider,
  getLayoutName,
} from '@wix/communities-blog-client-common';
import { getPostListPosts } from '../../../common/selectors/post-selectors';
import { connect } from '../../../common/components/runtime-context';
import NoPostsFeed from '../../../common/containers/no-posts-feed';
import PostListProGallery from '../../../common/components/post-list-pro-gallery';
import { getIsEntityLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getAppSettingsValue } from '../../../common/selectors/app-settings-base-selectors';
import Pagination from '../../../common/containers/pagination/pagination';
import { getPostCount } from '../../../common/store/post-count/post-count-selector';
import { getPostListWidgetPageSize } from '../../selectors/post-list-widget-page-size';

import styles from './post-list.scss';
import withDeviceType from '../../../common/hoc/with-device-type';
import { getPostListLayoutType } from '../../selectors/post-list-layout-type';
import {
  getUseMobileDisplaySettings,
  getUseMobileLayoutSettings,
} from '../../../common/selectors/app-settings-selectors';
import {
  getShouldUsePostListMobileSliderArrowColor,
  getShouldUsePostListMobileSliderBorderStyles,
} from '../../../common/selectors/mobile-settings-selectors';

class PostList extends Component {
  state = { page: 1 };

  componentDidUpdate({ pageSize, featuredOnly, isPaginationEnabled }) {
    if (
      this.props.pageSize !== pageSize ||
      this.props.featuredOnly !== featuredOnly ||
      this.props.isPaginationEnabled !== isPaginationEnabled
    ) {
      const page = 1;
      this.setState({ page });
      this.props.fetchPostListPosts(page);
    }
  }

  handlePageClick = ({ page }) => {
    this.setState({ page });
    this.props.fetchPostListPosts(page);
  };

  canSeeMoreButton = () => false;

  render() {
    const {
      posts,
      postCount,
      isLoading,
      componentId,
      layoutType,
      layoutName,
      layoutOptions,
      hideCoverImage,
      pageSize,
      isPaginationEnabled,
      shouldUsePostListMobileSliderArrowColor,
      shouldUsePostListMobileSliderBorderStyles,
      sliderArrowsPositions,
    } = this.props;
    const hasPosts = Boolean(posts.length);

    if (!isLoading && !hasPosts) {
      return <NoPostsFeed />;
    }

    if (!isEqual(this.layoutOptions, layoutOptions)) {
      this.layoutOptions = layoutOptions;
    }
    const postListRootClassNames = classNames({
      'mobile-slider-arrow-color': shouldUsePostListMobileSliderArrowColor,
      'mobile-slider-border-styles': shouldUsePostListMobileSliderBorderStyles,
    });

    return (
      <div className={postListRootClassNames}>
        <PostListProGallery
          className={layoutOptions.arrowsPosition === sliderArrowsPositions.ON_GALLERY ? 'arrows-on-gallery' : ''}
          layoutName={layoutName}
          layoutType={layoutType}
          entityCount={POST_LIST_WIDGET_DEFAULTS.entityCount}
          layoutDefaults={POST_LIST_WIDGET_DEFAULTS}
          domId={componentId}
          allPosts={posts}
          currentPagePosts={posts}
          isLoading={isLoading}
          showCreatePostAction={false}
          section={SECTION_POST_LIST}
          canSeeMoreButton={this.canSeeMoreButton}
          layoutOptions={this.layoutOptions}
          hideCoverImage={hideCoverImage}
        />
        {isPaginationEnabled && (
          <Pagination
            useDescriptionColor={true}
            containerClassName={styles.paginationContainer}
            page={this.state.page}
            onChange={this.handlePageClick}
            pageSize={pageSize}
            section={SECTION_POST_LIST}
            entityCount={postCount}
            createPageUrl={() => {}}
            activeClass="blog-post-list-pagination-active"
          />
        )}
      </div>
    );
  }
}

const mapRuntimeToProps = (state, { isMobile }, actions, host) => {
  const layoutType = getPostListLayoutType({ state, isMobile });

  const layoutName = getLayoutName(layoutType) || POST_LIST_WIDGET_DEFAULTS.layoutName;
  const paginationType = getAppSettingsValue({
    state,
    key: POST_LIST_SETTINGS_PARAMS.paginationType.appSettingsPath,
    fallback: POST_LIST_SETTINGS_PARAMS.paginationType.defaultValue,
  });
  const {
    sliderShowArrows,
    sliderArrowsSize,
    sliderAutoSlide,
    sliderPauseTime,
    sliderArrowsPosition,
    sliderArrowsColor,
    sliderLoop,
  } = getUseMobileLayoutSettings(state) ? POST_LIST_LAYOUT_PARAMS_MOBILE : POST_LIST_LAYOUT_PARAMS;
  return {
    isPaginationEnabled:
      !isLayoutSlider(layoutType) &&
      String(paginationType) !== String(POST_LIST_SETTINGS_PARAMS.paginationType.values.NUMBER_OF_POSTS),
    postCount: getPostCount(state),
    posts: getPostListPosts(state),
    layoutType,
    layoutName,
    isLoading: getIsEntityLoading(state, 'postListPosts'),
    componentId: host.id,
    pageSize: getPostListWidgetPageSize(state),
    sliderArrowsPositions: sliderArrowsPosition.values,
    hideCoverImage: !getAppSettingsValue({
      state,
      key: getUseMobileDisplaySettings(state) ? IS_COVER_IMAGE_ENABLED_MOBILE_PATH : IS_COVER_IMAGE_ENABLED_PATH,
      fallback: true,
    }),
    layoutOptions: {
      showArrows: getAppSettingsValue({
        state,
        key: sliderShowArrows.appSettingsPath,
        fallback: sliderShowArrows.defaultValue,
      }),
      arrowsSize: getAppSettingsValue({
        state,
        key: sliderArrowsSize.appSettingsPath,
        fallback: sliderArrowsSize.defaultValue,
      }),
      autoSlide: getAppSettingsValue({
        state,
        key: sliderAutoSlide.appSettingsPath,
        fallback: sliderAutoSlide.defaultValue,
      }),
      pauseTime: getAppSettingsValue({
        state,
        key: sliderPauseTime.appSettingsPath,
        fallback: sliderPauseTime.defaultValue,
      }),
      arrowsPosition: getAppSettingsValue({
        state,
        key: sliderArrowsPosition.appSettingsPath,
        fallback: sliderArrowsPosition.defaultValue,
      }),
      arrowsColor: getAppSettingsValue({
        state,
        key: sliderArrowsColor.appSettingsPath,
        fallback: sliderArrowsColor.defaultValue,
      }),
      loop: getAppSettingsValue({
        state,
        key: sliderLoop.appSettingsPath,
        fallback: sliderLoop.defaultValue,
      }),
    },
    shouldUsePostListMobileSliderArrowColor: getShouldUsePostListMobileSliderArrowColor(state),
    shouldUsePostListMobileSliderBorderStyles: getShouldUsePostListMobileSliderBorderStyles(state),
    featuredOnly: getAppSettingsValue({ state, key: POST_LIST_SETTINGS_PARAMS.isFeatured.appSettingsPath }),
    fetchPostListPosts: actions.fetchPostListPostsPromisified,
  };
};

export default flowRight(withDeviceType, connect(mapRuntimeToProps))(PostList);
